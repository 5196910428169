import { useEffect, useCallback, useRef } from 'react';

export function useResize(items, refreshHash) {
  const flickityRef = useRef();

  useEffect(() => {
    if (flickityRef && flickityRef.current) {
      // since there's no event on flickity for when an image finishes loading (non lazy)
      setTimeout(() => flickityRef.current.resize(), 100);
    }
  }, [items, refreshHash]);

  const flickityRefCallback = useCallback((flickity) => {
    if (flickity) {
      flickityRef.current = flickity;
    }
  }, []);

  return [flickityRefCallback, flickityRef];
}
