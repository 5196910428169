import React from 'react';

import { BuilderBlocks } from '@builder.io/react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  Carousel as ReactCarousel,
  useBreakpoint,
} from '~/techstyle-shared/react-components';

import { useResize } from '../../utils/useResize';

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const cellStyle = css`
  width: 100%;
`;

const StyledCarousel = styled(ReactCarousel).attrs({
  cellStyle,
})`
  & .flickity-prev-next-button,
  .flickity-prev-next-button:disabled {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.upsellCarouselBorder};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    width: 48px;
    height: 48px;

    & .flickity-button-icon {
      width: 15px;
      height: 15px;
      left: 34%;
      top: 34%;
    }

    &.previous {
      left: -22px;
    }

    &.next {
      right: -22px;
    }
  }

  .flickity-page-dots {
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flickity-page-dots > .dot {
    background: ${({ theme }) => theme.colors.flickityPageDots};
    width: 10px;
    height: 10px;
    margin: 0 9px;
    opacity: 1;
  }

  .dot.is-selected {
    background: ${({ theme }) => theme.colors.palette.white};
    width: 12px;
    height: 12px;
    opacity: 1;
  }

  .flickity-slider {
    display: flex;
    align-items: center;
  }

  .flickity-viewport {
    min-height: 175px;
  }

  .is-selected {
    text-align: center;
  }
`;

const BuilderCarousel = (props) => {
  const { isMobile } = useBreakpoint(null);

  const refreshHash = `${isMobile ? 'mobile' : 'desktop'}`;
  const [flickityRefCallback] = useResize(props.slides, refreshHash);

  return (
    <CarouselContainer data-builder-component-type="storytelling_carousel">
      {props.slides && (
        <StyledCarousel
          flickityRef={flickityRefCallback}
          autoPlay={props.autoscroll}
          cellStyle={cellStyle}
          prevNextButtons={props.slides.length > 1 && !isMobile}
          pageDots={props.showdots && props.slides.length > 1}
          wrapAround
        >
          {props.slides.map((slide, index) => (
            <BuilderBlocks
              key={index}
              parentElementId={props.builderBlock && props.builderBlock.id}
              dataPath={`component.options.slides.${index}.content`}
              child
              blocks={slide.content || slide}
            />
          ))}
        </StyledCarousel>
      )}
    </CarouselContainer>
  );
};

BuilderCarousel.propTypes = {
  autoscroll: PropTypes.bool,
  builderBlock: PropTypes.any,
  showdots: PropTypes.bool,
  slides: PropTypes.array,
};

export default BuilderCarousel;
